<template>
  <div>
    <div class="operations">
      <div style="flex: 1"></div>
      <a-button @click="addVehicleBrandVisible = true">添加</a-button>
    </div>
    <TiyoTable :gData="dataSource" :gColumns="columns" :gInnerColumns="innerColumns" />
  </div>
</template>
<script>
import { getPetUsers } from '../http/api';
import TiyoTable from '../components/TiyoTable.vue';
export default {
  components: {
    TiyoTable,
  },

  data() {
    return {
      vehicleBrandId: '',
      formState: {},
      addVehicleBrandVisible: false,
      addVehicleVisible: false,
      isShow: false,
      imgUrl: '',
      dataSource: [],
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          key: 'id',
          width: 80,
        },
        {
          title: 'unionId',
          dataIndex: 'unionId',
          key: 'unionId',
        },
        {
          title: '创建时间',
          dataIndex: 'created',
          key: 'created',
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          key: 'nickname',
        },
        {
          title: '头像',
          dataIndex: 'avatarUrl',
          key: 'avatarUrl',
          customRender: val => {
            return <a-image width="200" src={(val.value && val.value) || ''} />;
          },
        },
        {
          title: '手机号',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
        },
      ],
      innerColumns: [
        {
          title: '宠物昵称',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '宠物类型',
          dataIndex: 'specie',
          key: 'specie',
        },
        {
          title: '宠物头像',
          dataIndex: 'avatar',
          key: 'avatar',
          customRender: val => {
            return <a-image width="200" src={(val.value && val.value.url) || ''} />;
          },
        },
        {
          title: '品种',
          dataIndex: 'breed',
          key: 'breed',
        },
        {
          title: '绝育',
          dataIndex: 'isNeutered',
          key: 'isNeutered',
          customRender: val => {
            return val.value ? '是' : '否';
          },
        },
        {
          title: '体重/kg',
          dataIndex: 'weight',
          key: 'weight',
        },
        {
          title: '体型',
          dataIndex: 'bodyShape',
          key: 'bodyShape',
        },
        {
          title: '性别',
          dataIndex: 'gender',
          key: 'gender',
        },
      ],
    };
  },
  created() {
    this.getPetUsers();
  },
  methods: {
    showImg(url) {
      console.log(url);
      this.isShow = true;
      this.imgUrl = url;
    },

    getPetUsers() {
      getPetUsers().then(res => {
        if (res.errors) {
          this.$message.error(res.errors[0].message);
          return;
        }
        let dataSource = res.data.petUsers;
        dataSource.forEach(e => (e.innerData = e.pets));
        this.dataSource = dataSource;
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
<style>
.ant-image {
  width: 36px;
}
</style>
